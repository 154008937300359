import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Wrapper,
  Inner,
  WrapVideo,
  TitleVideo,
  WrapRukovoditeli,
  InnerRuk,
  WrapButton,
  WrapTeam,
  DescrTeam,
  WrapPhoto,
  WrapRow,
  HalfPart,
  WrapAddition,
  WrapTargets,
  InnerTargets,
  HalfTargets,
  NeedHelpArea
} from "./aboutus-styled";
import director from './director.JPG';
import arrow from './arrow.png';

import two from "./img/2t.png";
import three from "./img/3t.png";
import four from "./img/4t.png";
import five from "./img/5t.png";
import six from "./img/6t.png";
import oneT from "./img/1t.JPG";
import twoT from "./img/2t.JPG";
import threeT from "./img/3t.JPG";
import fourT from "./img/4t.JPG";
import fiveT from "./img/5t.JPG";
import sixT from "./img/6t.JPG";
import sevenT from "./img/7t.JPG";
import eightT from "./img/8t.JPG";
import nineT from "./img/9t.JPG";
import tenT from "./img/10t.JPG";


export const AboutUs = () => {
  const isMobile= window.innerWidth > 900;

  const location = useLocation();
  if (location.hash.includes('#special-element')) {
    setTimeout(() => {
      const specialElement = document.getElementById('special-element');
      specialElement.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  return (
    <Wrapper>
      <Inner>
       <h2>О нас</h2>
        <WrapVideo>
          <iframe width="100%" height={isMobile ? "100%": "100%"}
                  src="https://dzen.ru/embed/vgqBMONrbfw4?from_block=partner&from=zen&backoffice=1&mute=1&autoplay=1&tv=0"
                  allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
                  frameBorder="0" scrolling="no" allowFullScreen="" title="О нас"></iframe>
          <TitleVideo style={{ textAlign: "right" }}>Снято в 2020 году</TitleVideo>
        </WrapVideo>
        <WrapRukovoditeli>
          <InnerRuk>
            <p>Руководитель центра</p>
            <h2>Мария Студеникина</h2>
            <a href="mailto:director@domdliamamy.ru" className="email-studenikina">director@domdliamamy.ru</a>
            <img className="about-director" src={director} alt="ДомДляМамы"/>
            <Link to="/studenikina">
              <WrapButton>
                <img src={arrow} alt="ДомДляМамы"/>
                <span>Читать подробнее</span>
              </WrapButton>
            </Link>
          </InnerRuk>

        </WrapRukovoditeli>
        <WrapTeam>
          <div className="title-wrap-team">Команда</div>
          <DescrTeam>
            Последний год в штате кризисного Центра трудилось всего четверо сотрудников. Им помогали специалисты из смежных благотворительных проектов, волонтеры. Но время идет, наша команда расширяется и развивается. С первого июня 2022 нас уже больше :)
          </DescrTeam>
        </WrapTeam>
        <WrapPhoto>
          <WrapRow>
            <HalfPart>
              <div>
                <img src={oneT} alt="ДомДляМамы"/>
                <p className="name-team">Ирина Порываева</p>
                <p className="team-function">Директор по фандрайзингу</p>
                <a href="tel:+79684097814" style={{color: "rgb(105, 186, 193)"}}>+7 (968) 409-78-14</a><br/>
                <a href="mailto:Poryvaeva@domdliamamy.ru"
                   style={{color: "rgb(105, 186, 193)"}}>Poryvaeva@domdliamamy.ru</a>
              </div>
            </HalfPart>

            <HalfPart>
              <div>
                <img src={twoT} alt="ДомДляМамы"/>
                <p className="name-team">Светлана Краюшкина</p>
                <p className="team-function">SMM-менеджер</p>
                <a href="mailto:krayushkina@domdliamamy.ru" style={{color: "rgb(105, 186, 193)"}}>krayushkina@domdliamamy.ru</a>
              </div>
            </HalfPart>
          </WrapRow>
          <WrapRow>
            <HalfPart>
              <div>
                <img src={threeT} alt="ДомДляМамы"/>
                <p className="name-team">Ольга Крючкова</p>
                <p className="team-function">Специалист по социальной работе</p>
                <a href="mailto:social@domdliamamy.ru" style={{color: "rgb(105, 186, 193)"}}>social@domdliamamy.ru</a>
              </div>
            </HalfPart>

            <HalfPart>
              <div>
                <img src={fourT} alt="ДомДляМамы"/>
                <p className="name-team">Наталья Бондуровская</p>
                <p className="team-function">Психолог</p>
                <a href="mailto:nat@domdliamamy.ru" style={{color: "rgb(105, 186, 193)"}}>nat@domdliamamy.ru</a>
              </div>
            </HalfPart>
          </WrapRow>

          <WrapRow>
            <HalfPart>
              <div>
                <img src={fiveT} alt="ДомДляМамы"/>
                <p className="name-team">Любовь Савинская</p>
                <p className="team-function">Специалист по работе с внешними подопечными</p>
                <a href="mailto:help@domdliamamy.ru" style={{color: "rgb(105, 186, 193)"}}>help@domdliamamy.ru</a>
              </div>
            </HalfPart>

            <HalfPart>
              <div>
                <img src={sixT} alt="ДомДляМамы"/>
                <p className="name-team">Виктория Гребенюк</p>
                <p className="team-function">Администратор приюта</p>
                <a href="mailto:adminpriut@domdliamamy.ru" style={{color: "rgb(105, 186, 193)"}}>adminpriut@domdliamamy.ru</a>
              </div>
            </HalfPart>
          </WrapRow>
          <WrapRow>
            <HalfPart>
              <div>
                <img src={sevenT} alt="ДомДляМамы"/>
                <p className="name-team">Ирина Пекшева</p>
                <p className="team-function">Дежурная консультационного центра</p>
                <a href="tel:+79652629878" style={{color: "rgb(105, 186, 193)"}}>+7 965 262-98-78</a><br/>
                <a href="mailto:duty@domdliamamy.ru"
                   style={{color: "rgb(105, 186, 193)"}}>duty@domdliamamy.ru</a>
              </div>
            </HalfPart>

            <HalfPart>
              <div>
                <img src={eightT} alt="ДомДляМамы"/>
                <p className="name-team">Наталья Гапоненко</p>
                <p className="team-function">Дежурная консультационного центра</p>
                <a href="tel:+79652629878" style={{color: "rgb(105, 186, 193)"}}>+7 965 262-98-78</a><br/>
                <a href="mailto:duty@domdliamamy.ru"
                   style={{color: "rgb(105, 186, 193)"}}>duty@domdliamamy.ru</a>
              </div>
            </HalfPart>
          </WrapRow>
          <WrapRow>
            <HalfPart>
              <div>
                <img src={nineT} alt="ДомДляМамы"/>
                <p className="name-team">Александр Рысев</p>
                <p className="team-function">Руководитель склада гуманитарной помощи</p>
                <a href="mailto:sklad@domdliamamy.ru" style={{color: "rgb(105, 186, 193)"}}>sklad@domdliamamy.ru</a>
              </div>
            </HalfPart>

            <HalfPart>
              <div>
                <img src={tenT} alt="ДомДляМамы"/>
                <p className="name-team">Любовь Барсукова</p>
                <p className="team-function">Сотрудник склада</p>
                <a href="mailto:sklad@domdliamamy.ru" style={{color: "rgb(105, 186, 193)"}}>sklad@domdliamamy.ru</a>
              </div>
            </HalfPart>
          </WrapRow>
          {/*  <HalfPart>*/}
          {/*    <Link to="/about/zam-directora">*/}
          {/*      <img src={two} alt="ДомДляМамы"/>*/}
          {/*      <p className="name-team">Екатерина Фоминцева</p>*/}
          {/*      <p className="team-function">заместитель директора, администратор приюта</p>*/}
          {/*    </Link>*/}
          {/*  </HalfPart>*/}

          {/*  <HalfPart>*/}
          {/*    <Link to="/about/specialist-po-sotsialinoi-rabote">*/}
          {/*      <img src={five} alt="ДомДляМамы"/>*/}
          {/*      <p className="name-team">Ольга Крючкова</p>*/}
          {/*      <p className="team-function">специалист по социальной работе</p>*/}
          {/*    </Link>*/}
          {/*  </HalfPart>*/}
          {/*</WrapRow>*/}
          {/*<WrapRow>*/}
          {/*  <HalfPart>*/}
          {/*    <Link to="/about/psyholog">*/}
          {/*      <img src={four} alt="ДомДляМамы"/>*/}
          {/*      <p className="name-team">Наталья Бондуровская</p>*/}
          {/*      <p className="team-function">психолог</p>*/}
          {/*    </Link>*/}
          {/*  </HalfPart>*/}
          {/*  <HalfPart>*/}
          {/*    <Link to="/about/iurist">*/}
          {/*      <img src={three} alt="ДомДляМамы"/>*/}
          {/*      <p className="name-team">Юрий Козубовский</p>*/}
          {/*      <p className="team-function">юрист</p>*/}
          {/*    </Link>*/}
          {/*  </HalfPart>*/}
          {/*</WrapRow>*/}
          {/*<WrapRow>*/}
          {/*  <HalfPart>*/}
          {/*    <Link to="/about/spetstialist-po-sotsialinoi-rabote">*/}
          {/*      <img src={six} alt="ДомДляМамы"/>*/}
          {/*      <p className="name-team">Любовь Савинская</p>*/}
          {/*      <p className="team-function">специалист по работе с внешними подопечными</p>*/}
          {/*    </Link>*/}
          {/*  </HalfPart>*/}
          {/*  <HalfPart style={{ pointerEvents: "none" }}>*/}
          {/*  </HalfPart>*/}
          {/*</WrapRow>*/}
        </WrapPhoto>
        <WrapAddition>
          Сотрудники Центра помощи «Дом для мамы» — не только команда профессионалов, но и объединение неравнодушных людей, связанных общей идеей — помочь как можно большему числу женщин, оказавшимся в кризисной ситуации, и сделать все, чтобы в нашем обществе становилось меньше таких нуждающихся.
        </WrapAddition>
        <span id="special-element" />
        <WrapTargets>
          <h3>Цели «Дома для мамы»</h3>
          <InnerTargets>
            <HalfTargets>
              <ul>
                <li>предоставление услуг по социальной реабилитации беременных женщин и матерей с детьми, попавших в трудную жизненную ситуацию, в том числе содействие указанным лицам в получении ими медицинской помощи в лечебных учреждениях и в трудоустройстве;</li>
                <li>открытие реабилитационных центров для оказания социальной и иной помощи беременным женщинам и матерям с детьми, попавших в трудную жизненную ситуацию;</li>
                <li>социальная поддержка и защита граждан, включая материальное положение малообеспеченных, социальная реабилитация безработных, инвалидов и иных лиц, которые в силу своих физических или интеллектуальных особенностей, иных обстоятельств не способны самостоятельно реализовать свои права и законные интересы;</li>
              </ul>
            </HalfTargets>
            <HalfTargets>
              <ul>
                <li>содействие деятельности в сфере профилактики и охраны здоровья граждан, а также пропаганды здорового образа жизни, улучшения морально-психологического состояния граждан;</li>
                <li>содействие укреплению престижа и роли семьи в обществе;</li>
                <li>содействие защите материнства, детства и отцовства;</li>
                <li>содействие профилактики социально опасных форм поведения граждан;</li>
                <li>оказание бесплатной юридической помощи и правового просвещения населения;</li>
                <li>содействие добровольческой (волонтерской) деятельности.</li>
              </ul>
            </HalfTargets>
          </InnerTargets>

          <NeedHelpArea>
            <p>Помни, что ты не одна. Здесь тебе помогут!</p>
            <Link to="/nugnapomosi">
              Нужна помощь
            </Link>
          </NeedHelpArea>

        </WrapTargets>
      </Inner>
    </Wrapper>
  )
};
