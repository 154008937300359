import React from "react";
import {
  Inner,
  Wrapper,
  WrapSevenSixZero,
  Graytetxt,
  TwelveYears,
  Istok,
  IstokTitle,
  MoreText,
  Studies,
  StudiesTitle,
  Delo,
  DeloTitle,
  DeloText,
  NowMaria,
  WrapBooks,
  Deiatelinosti,
  TitleD,
  ContactsMaria
} from "./styled-studenikina";
import director from "./studenikina.png"

export const Studenikina = () => {
  return (
    <Wrapper>
      <Inner>
        <WrapSevenSixZero>
          <Graytetxt>Руководитель центра</Graytetxt>
          <h2>Мария Студеникина</h2>
          <a href="mailto:director@domdliamamy.ru" className="email-studenikina">director@domdliamamy.ru</a>
          <img className="photo-studenikina" src={director} alt="Дом для мамы"/>
          <TwelveYears>
            Более 12 лет Мария Михайловна занимается реализацией социальных проектов и программ. Направления ее основной деятельности связаны с вопросами: обеспечения социальной защиты отдельных категорий населения (программы адресной социальной помощи малообеспеченных граждан, беременных женщин, программы занятости женщин и т.д.), развития системы центров помощи женщинам, центров милосердия, детских домов семейного типа и т.д.<br/><br/>
            Имеет опыт работы в области разработчика и участника проектных мероприятий, является экспертом в области оценки социальных проектов более  9 лет.
          </TwelveYears>
          <Istok>
            <IstokTitle>
              Мария Михайловна стояла у истоков организации помощи женщинам, оказавшимся в кризисной ситуации в нашей стране:
              <ul>
                <li>Придумала и организовала первый в России Центр гуманитарной помощи (ЦГП).</li>
                <li>Под ее руководством, в феврале 2012 года, создан московский Центр помощи «Дом для мамы» - первый кризисный центр такого рода.</li>
              </ul>
            </IstokTitle>
          </Istok>
          <MoreText>
            Начинала свой путь в качестве добровольца — участвовала в организации помощи регионам, пострадавшим от лесных пожаров в 2010 г. Ее живое, искреннее, бескорыстное участие в гуманитарной деятельности не осталось незамеченным. В 2012 году именно ей предложили организовать и возглавить «Дом для мамы».
          </MoreText>
          <Studies>
            <h5>Образование</h5>
            <StudiesTitle>
              2010 г. — диплом с отличием по направлению «Экономика» в ГОУ ВПО «Московский энергетический институт (Национальный исследовательский университет)».<br/><br/>
              2012 — степень магистра экономики в том же университете.<br/><br/>
            </StudiesTitle>
            <Delo>
              <DeloTitle>
                Мария Михайловна живет своим делом!
              </DeloTitle>
              <DeloText>
                Под ее руководством в Центре  помощи «Дом для мамы» удалось вывести из тяжелой кризисной ситуации более 1000 женщин и детей, оказать гуманитарную помощь более 25000 нуждающимся.
              </DeloText>
            </Delo>
          </Studies>
          <NowMaria>
            <h6>Сейчас Мария Михайловна:</h6>
            <ul>
              <li>Директор московского Центра помощи «Дом для мамы».</li>
              <li>При финансовой и методической поддержке Центра открыто 86 приютов для беременных и женщин с детьми, а также более 250 центров гуманитарной помощи по всей России.</li>
              <li>Руководитель Программы помощи Центрам защиты семьи, материнства и детства В. Потанина.</li>
              <li>Руководитель направления профилактики абортов Отдела по церковной благотворительности и социальному служению РПЦ (МП).</li>
              <li>Координатор двух Общецерковных сборов средств на нужды кризисных беременных и малообеспеченных семей.</li>
              <li>Соавтор трех методических пособий:</li>
            </ul>
            <WrapBooks>
              <a href="https://domdliamamy.ru/donation/wp-content/uploads/2022/02/tsentr_gum_pomoshchi.pdf" download="">«Опыт создания церковного центра гуманитарной помощи»</a>
              <a href="https://domdliamamy.ru/donation/wp-content/uploads/2022/02/mama-home_block_1-224_new2.pdf" download="">«Приют для будущих мам: от плана до воплощения»</a>
              <a href="https://domdliamamy.ru/donation/wp-content/uploads/2022/02/dom_dlya_mami_blok_to_site.pdf" download="">«Психолог помогает мамам»</a>
            </WrapBooks>
          </NowMaria>
          <Deiatelinosti>
            <TitleD>
              За свою деятельность Мария Михайловна удостоена наград:
            </TitleD>
            <ul>
              <li>Юбилейная медаль Русской Православной Церкви «В память 1000-летия преставления равноапостольного великого князя Владимира»</li>
              <li>Медаль святого праведного Филарета Милостивого (III степени)</li>
              <li>Грамота Президента российской Федерации В.В. Путина «За бескорыстный вклад в организацию Общероссийской акции взаимопомощи «Мы вместе»</li>
              <li>Благодарность уполномоченного по правам ребенка в Брянской области «За помощь в деле защиты семьи, материнства и детства, оказанную поддержку семьям Брянской области, оказавшимся в трудной жизненной ситуации».</li>
            </ul>
          </Deiatelinosti>
          <ContactsMaria>
            Если вам необходимо связаться с Марией Михайловной, вы можете обратиться в наш Центр по тел. <a href="tel:+79652629878" rel="noopener noreferrer" className="centr-tel">+7 965 262-98-78</a> или написать на адрес электронной почты  <a href="mailto:director@domdliamamy.ru" className="email-studenikina-bottom">director@domdliamamy.ru</a>
          </ContactsMaria>
        </WrapSevenSixZero>
      </Inner>
    </Wrapper>
  )
};
